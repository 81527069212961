<template>
  <div class="booking-main" :class="{ 'dpv-main': showDriver == 1 }">
    <div class="booking-body">
      <div class="booking-fold" :class="{ active: currentBooking != null }">
        <div class="booking-table table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>No</th>
                <th>Service Date</th>
                <th>Title</th>
                <th>Vehicle</th>
                <th>Pick-up</th>
                <th>Drop-off</th>
                <th>Status</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody v-if="rows.length > 0 && !fetchingData">
              <tr
                v-for="(booking, index) in rows"
                v-bind:key="index"
                @click="showBooking(+booking.id)"
                style="cursor: pointer"
                :class="
                  currentBooking == booking.id
                    ? 'nth-child-border'
                    : !currentBooking && index == 0
                    ? 'first-child-border'
                    : ''
                "
              >
                <td>
                  {{ tableInfo.currentPage * tableInfo.perPage - tableInfo.perPage + index + 1 }}
                </td>
                <td>{{ moment(booking.service_date).format('D MMM YYYY') }}</td>
                <td>{{ booking.title_of_booking }}</td>
                <td>{{ booking.booking_vehicles_detail.join(', ') }}</td>
                <td v-if="booking.pickup_address && booking.pickup_address.length < 30">
                  {{ booking.pickup_address }}
                </td>
                <td v-else-if="booking.pickup_address">
                  {{ booking.pickup_address.substring(0, 30) + '..' }}
                </td>
                <td v-if="booking.drop_address && booking.drop_address.length < 30">
                  {{ booking.drop_address }}
                </td>
                <td v-else-if="booking.drop_address">
                  {{ booking.drop_address.substring(0, 30) + '..' }}
                </td>
                <td
                  class="ongoing status"
                  :class="
                    booking.booking_status == '1'
                      ? 'text-info'
                      : booking.booking_status == '2'
                      ? 'complete status'
                      : 'text-primary'
                  "
                >
                  {{ booking.booking_status_name }}
                </td>
                <td>{{ booking.suggested_budget_amount }} AUD</td>
              </tr>
            </tbody>
            <tbody v-if="fetchingData">
              <tr>
                <td colspan="100%" style="text-align: center">
                  <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-if="rows.length == 0 && !fetchingData">
              <tr>
                <td colspan="100%" style="text-align: center">
                  <img
                    class="noDataImage"
                    :src="require('@/assets/images/nodata.svg')"
                    alt=""
                  /><br /><br />
                  <b>No data to show</b>
                </td>
              </tr>
            </tbody>
          </table>
          <pagination
            v-if="rows.length > 0 && !fetchingData"
            :perPage="serverParams.perPage"
            :totalRows="tableInfo.total"
            :value="serverParams.page"
            v-on:pagechanged="pagechanged"
            v-on:perPagechanged="perPagechanged"
          ></pagination>
        </div>
        <div
          class="booking-flow flex-fill"
          v-if="Bookingdata != null && !isFetchingBooking && showDriver != 1"
        >
          <div class="booking-map">
            <figure style="height:100%!important">
              <div class="spinner-border text-primary mx-auto mt-5" v-if="isFatchMapImage"
              style="position: absolute;left: 50%;top: 10%"></div>
              <img
                v-if="location_map_image_url != null"
                :src="location_map_image_url"
                alt=""
              />
            </figure>
            <div class="booking-map-info">
              <div class="refrence-num" v-if="Bookingdata.booking_reference_id">
                Reference: {{ Bookingdata.booking_reference_id }}
                <img
                  :src="require('@assets/images/copy.svg')"
                  style="cursor: pointer"
                  @click="referenceNoCopyToClipBoard(Bookingdata.booking_reference_id)"
                />
              </div>
              <!-- {{pickup_latitude pickup_longitude drop_latitude drop_longitude}} -->
              <a
                :href="`https://www.google.com/maps/dir/?api=1&origin=${Bookingdata.pickup_latitude},${Bookingdata.pickup_longitude}&destination=${Bookingdata.drop_latitude},${Bookingdata.drop_longitude}`"
                class="view-map"
                target="_blank"
                >View map</a
              >
            </div>
          </div>
          <div class="booking-detail">
            <div class="shipping-info">
              <h2>{{ Bookingdata.title_of_booking }}</h2>
              <div class="track">
                <ul>
                  <li>
                    <figure>
                      <img :src="require('@assets/images/pin-sharp-circle.svg')" alt="" />
                    </figure>
                    <div class="track-location">
                      <h4>PICK-UP LOCATION</h4>
                      <span>{{ Bookingdata.pickup_address }}</span>
                    </div>
                  </li>
                  <li v-if="Bookingdata.drop_address">
                    <figure>
                      <img :src="require('@assets/images/pin-sharp-dot.svg')" alt="" />
                    </figure>
                    <div class="track-location">
                      <h4>DROP-OFF LOCATION</h4>
                      <span>{{ Bookingdata.drop_address }}</span>
                    </div>
                  </li>
                </ul>
                <div class="budget">
                  <h4>Task Budget</h4>
                  <span>{{ Bookingdata.suggested_budget_amount }}</span>
                </div>
              </div>
              <div class="service-date">
                <figure>
                  <img :src="require('@assets/images/calendar.svg')" alt="" />
                </figure>
                <div class="track-location">
                  <h4>Service Date</h4>
                  <span>{{ moment(Bookingdata.service_date).format('dddd,Do MMM YYYY') }}</span>
                </div>
              </div>
              <div class="detail">
                <label>Details</label>
                <p>
                  {{ Bookingdata.description }}
                </p>
              </div>
              <div class="availble-photos" v-show="Bookingdata.attechments.length > 0">
                <label>photos</label>
                <div class="av-photo-list">
                  <figure
                    :key="`b-attachemnts-${key}`"
                    v-for="(attachment, key) in Bookingdata.attechments"
                  >
                    <img
                      v-if="
                        attachment.image_url != null &&
                        attachment.attechment_name.split('.').pop().toLowerCase() != 'pdf'
                      "
                      :src="attachment.image_url"
                      alt=""
                      style="cursor: pointer !important"
                      @click="openModalForShowImage(attachment.image_url)"
                    />
                    <a :href="attachment.image_url" target="_blank">
                      <img
                        v-if="
                          attachment.image_url != null &&
                          attachment.attechment_name.split('.').pop().toLowerCase() == 'pdf'
                        "
                        :src="require('@assets/images/pdf-file.png')"
                        alt=""
                    /></a>
                  </figure>
                </div>
              </div>
            </div>
            <div class="bids-detail">
              <h5 v-if="this.page != 'Completed'">
                Bids<span>({{ totalBids }})</span>
              </h5>
              <div
                v-show="typeof Bidsdata !== 'undefined' && Bidsdata.length > 0"
                class="bids-list"
                v-for="bid in Bidsdata"
                :key="bid.id"
                @click="showDriverProfile(+bid.booking_id, +bid.user_id)"
                style="cursor: pointer"
              >
                <div class="bid-item">
                  <div class="bid-active">
                    <small>Active 4h ago</small>
                    <div class="bid-user">
                      <figure>
                        <avatar
                          v-if="bid.user.image_url == null"
                          :username="bid.user.full_name"
                          class="w-auto h-100"
                        ></avatar>
                        <img v-if="bid.user.image_url != null" :src="bid.user.image_url" alt="" />
                      </figure>
                      <div class="bu-info">
                        <h5>{{ bid.user.full_name }}</h5>
                        <span>{{ bid.user.location }}Bondi NSW'</span>
                      </div>
                    </div>
                  </div>
                  <div class="bid-job">
                    <small>{{ bid.user.driver_total_booking }} jobs</small>
                    <div class="bid-rating">
                      <span>{{ Number(bid.user.user_driver_rating_review_avg) }}/5</span>
                      <div class="ratiing-star">
                        <i class="fas fa-star"></i>
                      </div>
                    </div>
                    <div class="price"><span></span>{{ bid.bid_amount }} AUD</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="booking-flow flex-fill" v-if="showDriver == 1">
          <div class="bf-header">
            <a
              class="back-link"
              href="javascript:;"
              @click="getspecificBooking(driverData.booking_id)"
            >
              <figure>
                <img :src="require('@assets/images/back-circle-arrow.png')" alt="" />
              </figure>
              Back to booking details
            </a>
            <div class="bf-tag">
              <div class="bf-price">
                <span>price</span>
                <h5>{{ driverData.bid_amount }} AUD</h5>
              </div>
              <div class="bf-communication">
                <figure>
                  <img :src="require('@assets/images/talking.png')" alt="" />
                </figure>
                <span>Communication inside the platform</span>
              </div>
            </div>
          </div>
          <div class="bf-body">
            <div class="bf-profile">
              <h4>Driver Profile</h4>
              <div class="profile-info">
                <figure>
                  <avatar
                    class="w-100 h-100"
                    v-if="driverData.user.image_url == null"
                    :username="driverData.user.full_name"
                  ></avatar>
                  <img
                    v-if="driverData.user.image_url != null"
                    :src="driverData.user.image_url"
                    alt=""
                  />
                </figure>
                <div class="profile-wrapper">
                  <div class="profile-des">
                    <h5>{{ driverData.user.full_name }}</h5>
                    <div class="status">
                      <span v-if="driverData.user.user_has_one_current_location">
                        Last Active: {{
                          moment(driverData.user.user_has_one_current_location.created_at).fromNow()
                        }}
                      </span>
                      <span v-if="driverData.user.last_live_location_suburb">
                        Last seen in: {{ driverData.user.last_live_location_suburb }}
                      </span>
                    </div>
                    <p>
                      {{ driverData.user.profile_description }}
                    </p>
                  </div>
                  <div class="bf-other-info">
                    <Verification
                      :verification-data="driverData.driver_documents_verified_or_not"
                    />
                    <div class="vehicle" v-if="driverData.user.driver_vehicles_detail">
                      <h4>VEHICLES</h4>
                      <div class="vehicl-list">
                        <div
                          class="vehicle-img"
                          v-for="v_detail in driverData.user.driver_vehicles_detail"
                          v-bind:key="v_detail.id"
                        >
                          <figure>
                            <img :src="v_detail.image_url" alt="" />
                          </figure>
                          <span>{{ v_detail.full_name }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="speciality">
                      <h4>Languages :</h4>
                      <p>{{driverData.user.languages ? driverData.user.languages : '-'}}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="bf-work-info" v-if="driverData.user_has_many_work_history.length > 0">
                <h4>Work History</h4>
                <ul>
                  <li
                    v-for="history in driverData.user_has_many_work_history"
                    v-bind:key="history.id"
                  >
                    <h3>
                      {{ history.title }} {{ history.start_date_year }}
                      <span v-if="history.end_date_year != null">-</span>
                      {{ history.end_date_year }}
                    </h3>
                    <p>
                      {{ history.description }}
                    </p>
                  </li>
                </ul>
              </div>
              <div class="bf-reviews" v-if="driverData.user_has_many_rating_review.length > 0">
                <h4>Reviews</h4>
                <div
                  class="review-list"
                  v-for="review in driverData.user_has_many_rating_review"
                  v-bind:key="review.id"
                >
                  <div class="rating d-flex">
                    <star-rating
                      :star-size="16"
                      :increment="0.50"
                      :rating="parseFloat(review.rating)"
                      read-only
                      :show-rating="false"
                      active-color="#00A6E3"
                    ></star-rating>
                    <span class="ml-2">{{ Number(review.rating) }}/5</span>
                  </div>
                  <p>
                    {{ review.review }}
                  </p>
                   <label>
                    {{ review.given_user_detail ? `-${review.given_user_detail.full_name}` : '' }}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="booking-action hide">
            <div class="bid-option" v-if="driverData.bid_status == '0'">
              <router-link
                :to="{
                  name: 'payment',
                  params: { bookingId: driverData.booking_id, bidId: driverData.user_id },
                }"
                tag="button"
                class="btn btn-primary"
              >
                Accept bid
              </router-link>

              <!-- <button
                type="button"
                :disabled="isRejectBid == 2"
                class="btn btn-primary"
                @click="acceptRejectBid(2)"
              >
                <span
                  v-if="isRejectBid == 2"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Accept Bid
              </button> -->
              <button
                type="button"
                :disabled="isRejectBid == 1"
                class="btn btn-reject"
                @click="RejectBid()"
              >
                <span
                  v-if="isRejectBid == 1"
                  class="spinner-border spinner-border-lg"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span v-if="isRejectBid !== 1">Reject bid</span>
              </button>
            </div>
            <button
              type="button"
              class="btn btn-msg"
              @click="callMessage(driverData.booking_id, driverData.user_id)"
            >
              <figure><img :src="require('@assets/images/comment.png')" /></figure>
              Message
            </button>
          </div>
        </div>
        <div class="spinner-border text-primary mx-auto mt-5" v-if="isFetchingBooking"></div>
      </div>
    </div>
    <imageShowModal
      :showImageinModalPath="showImageinModalPath"
      v-on:closeImageModel="showImageinModalPath = ''"
    ></imageShowModal>
  </div>
</template>

<script>
/* eslint-disable */
import moment from 'moment';
import Avatar from 'vue-avatar';
import StarRating from 'vue-star-rating';
import pagination from '@/components/pagination';
import Verification from '@/views/pages/DriverComponents/Verification';
import imageShowModal from '@/components/ImageShowModal';

export default {
  name: 'Bookings',
  components: { Avatar, StarRating, Verification, pagination, imageShowModal },
  data() {
    return {
      rows: [],
      Bookingdata: null,
      driverData: null,
      totalBids: 0,
      Bidsdata: {},
      tableInfo: {},
      totalRecords: 0,
      vehicle_option: [],
      showDriver: 0,
      booking_vehicles_detail: '',
      serverParams: {
        search: '',
        filterByVehicle: '',
        page: 1,
        perPage: 10,
      },
      currentBooking: null,
      fetchingData: false,
      isFetchingBooking: false,
      isRejectBid: 0,
      status: {
        Accept: 1,
        Complete: 2,
      },
      attachFile: null,
      isSelecting: false,
      selectedFile: null,
      deleteDateFilter: 0,
      showImageinModalPath: '',
      isFatchMapImage:false,
      location_map_image_url: '',
    };
  },
  watch: {
    page() {
      this.getDriverBooking();
    },
  },
  computed: {
    page() {
      this.serverParams.page = 1;
      this.serverParams.perPage = 10;
      return this.$route.query.status || 'all';
    },
  },
  created() {
    this.getDriverBooking();
  },
  methods: {
    moment,
    getDriverBooking() {
      this.fetchingData = true;
      this.currentBooking = null;
      this.$http
        .get('/driver/booking/list', {
          params: Object.assign(
            { ...this.serverParams },
            this.page == 'all'
              ? {}
              : {
                  status: [this.status[this.$route.query.status]],
                }
          ),
        })
        .then((res) => {
          const { collection, ...info } = res.data.data;
          this.rows = collection;
          this.tableInfo = info;
        })
        .catch((error) => {
          this.rows = [];
          this.$toast.error(error.response.data.message);
        })
        .finally(() => {
          this.fetchingData = false;
        });
    },
    getspecificBooking(bookingId) {
      this.showDriver = 0;
      this.isFetchingBooking = true;
      this.$http
        .get(`/driver/booking/show/${bookingId}`)
        .then((res) => {
          const collection1 = res.data.data;
          this.Bookingdata = collection1;
          this.totalBids = collection1.booking_has_many_placed_bids.length;
          this.Bidsdata = collection1.booking_has_many_placed_bids;
          this.showDriver = 0;
          this.location_map_image_url = collection1.location_map_image_url;
          if(!collection1.location_map_image){
            this.isFatchMapImage = true;
            this.fetchMapImage(bookingId)
          }
        })
        .catch((error) => {
          this.Bookingdata = null;
          this.currentBooking = null;

          this.$toast.error('Booking not found');
        })
        .finally(() => {
          this.isFetchingBooking = false;
        });
    },
    fetchMapImage(bookingId){
        this.$http
        .post(`customer/booking/getGoogleStaticMap`, {
          booking_id: bookingId,
        })
        .then((res) => {
          this.location_map_image_url = res.data.data;
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message);
        })
        .finally(() => {
          this.isFatchMapImage = false;
        });
    },
    showDriverProfile(booking_id, bidId) {
      this.isFetchingBooking = true;
      this.$http
        .get(`customer/booking/show/${booking_id}/driver_profile/${bidId}`)
        .then((res) => {
          this.driverData = res.data.data;
          this.showDriver = 1;
        })
        .catch((error) => {
          this.driverData = null;
          this.$toast.error('Driver not found');
        })
        .finally(() => {
          this.isFetchingBooking = false;
        });
    },
    showBooking(bookingId) {
      if (this.currentBooking === bookingId) {
        this.currentBooking = null;
        return;
      } else {
        this.currentBooking = bookingId;
        this.getspecificBooking(bookingId);
      }
    },
    updateParams(newProps) {
      // this.serverParams = { ...newProps };
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    pagechanged(e) {
      this.serverParams.page = Number(e);
      this.getDriverBooking();
    },
    perPagechanged(e) {
      this.serverParams.perPage = Number(e);
      this.getDriverBooking();
    },
    openModalForShowImage(openModalForShowImage) {
      this.showImageinModalPath = openModalForShowImage;
    },
    closeImageModel() {
      this.showImageinModalPath = '';
    },
  },
};
</script>

<style>
.active {
  display: block;
}
.option-heading {
  position: relative;
  display: inline-block;
}
.option-heading:after {
  content: '';
  position: absolute;
  display: block;
  width: 10px;
  height: 5px;
  background-size: contain;
  background-repeat: no-repeat;
  top: 6px;
  right: -15px;
  background-image: url('~@assets/images/triangle.svg');
}
.option-heading.active:after {
  transform: rotate(180deg);
}

/* Helpers */
.is-hidden {
  display: none;
}

.nth-child-border {
  border-bottom: 2px solid #00a6e3 !important;
  border-top: 2px solid #00a6e3 !important;
}
.first-child-border {
  border-bottom: 2px solid #00a6e3 !important;
  border-top: 2px solid #00a6e3 !important;
}
</style>
